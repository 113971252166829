.achievements {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-size: 35px;
        height: 60px;
        color: blue;
    }
    .container {
        margin: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .item {
            cursor: pointer;
            padding: 15px;
            background-color: beige;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 5px solid rosybrown;
            margin: 13px;
            border-radius: 12px;
            transition: all .5s ease-in-out;
            img {
                width: 100px;
                height: 100px;
                margin-right: 5px;
                fill: red;
            }
            span {
                max-width: 200px;
                font-size: 100%;
                color: black;
            }
            &:hover {
                transform: scale(1.1);
                font-size: 120%;
                background-color: aquamarine;
            }
        }
    }
}