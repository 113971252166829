.myskill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
        font-weight: 900;
        overflow: hidden;
    }
    .skills {
        height: 100%;
        display: flex;
        align-items: space-between;
        justify-content: center;
        flex-wrap: wrap;
        img {
            width: 150px;
            height: 150px;
            margin: 10px;
        }
        .title {
            text-align: center;
            font-size: 22px;
        }
    }
    @media screen and (max-width: 390px) {
        .skills {
            img {
                width: 100px;
                height: 100px;
            }
        }
    }
}