.topbar {
    width: 100%;
    height: 70px;
    background-color: #cfc9c9;
    color: #15023a;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 2s ease;
    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            .logo {
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                display: flex;
                justify-content: center;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }
        }
        .right {
            .hamburger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                span {
                    width: 100%;
                    height: 3px;
                    background-color: #15023a;
                    transform-origin: left;
                    transition: all 2s ease;
                }
            }
        }
    }
    &.active {
        background-color: #15023a;
        color: #fff;
        .hamburger {
            span {
                &:first-child {
                    background-color: #fff;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    background-color: #fff;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}