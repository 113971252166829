.home {
    background-color: #fff;
    display: flex;
    .left {
        flex: 0.5;
        overflow: hidden;
        .imgContainer {
            width: 700px;
            height: 700px;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
    }
    .right {
        flex: 0.5;
        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                font-size: 60px;
                margin: 10px 0;
                color: cornflowerblue;
            }
            h2 {
                font-size: 35px;
            }
            h3 {
                font-size: 30px;
                span {
                    font-size: inherit;
                    color: crimson;
                }
            }
        }
    }
}

@media only screen and (max-width: 426px) {
    .home {
        flex-direction: column;
        .left {
            padding: 10px;
            .imgContainer {
                width: 100%;
                height: 100%;
            }
        }
    }
}