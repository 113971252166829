.contact {
    background-color: #d8d4d4;
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
        overflow: hidden;
    }
    .container {
        display: flex;
        width: 100vw;
        height: 100%;
        .left {
            flex: 4;
            padding: 20px;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                height: 100%;
                li {
                    display: flex;
                    align-items: center;
                    margin: 5px;
                    &:hover {
                        img {
                            background-color: rgb(114, 111, 111);
                        }
                    }
                    img {
                        margin-right: 5px;
                        padding: 5px;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        /* background-color: #c5b38e; */
                    }
                    a {
                        text-decoration: none;
                        color: #00f;
                        font-weight: 900;
                        font-family: serif;
                        font-size: 15px;
                    }
                }
            }
        }
        .right {
            flex: 4;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                width: 80%;
                height: 80%;
                transform: rotate(-45deg);
            }
        }
        /* @media screen and (max-width: 390px) {
            .right {
                flex: 0;
            }
        } */
    }
}

@media only screen and (max-width: 426px) {
    .contact {
        .container {
            flex-direction: column-reverse;
        }
    }
}