.works {
    background-color: rgb(192, 201, 175);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;
        &.left {
            left: 100px;
            transform: rotate(270deg);
        }
        &.right {
            right: 100px;
            transform: rotate(90deg);
        }
    }
    .slider {
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;
        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                width: 700px;
                height: 100%;
                background-color: #fff;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .left {
                    flex: 4;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .leftContainer {
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .imgContainer {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #dcc6c6;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                width: 25px;
                            }
                        }
                        h2 {
                            font-size: 20px;
                            color: darkslateblue;
                            font-weight: 900;
                        }
                        p {
                            font-size: 100%;
                            text-align: justify;
                        }
                        a {
                            color: rgb(0, 30, 139);
                            width: auto;
                            text-decoration: none;
                            font-size: 14px;
                        }
                        span {
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                        }
                    }
                }
                .right {
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background-color: #dcc6c6;
                    img {
                        width: 400px;
                        transform: rotate(-10deg);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 426px) {
    .works {
        .arrow {
            &.left {
                left: 0;
            }
            &.right {
                right: 0;
            }
        }
        .slider {
            height: calc(90vh - 70px);
            .container {
                .item {
                    width: 90%;
                    flex-direction: column;
                    .right {
                        flex: 4;
                        img {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}